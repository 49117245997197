import React, { useRef, forwardRef } from "react";
import { DiGithubBadge } from "react-icons/di";
import { FaLinkedin } from "react-icons/fa";
import { FaPhone, FaEnvelope, FaLocationDot } from "react-icons/fa6";
import { Canvas, useFrame } from "@react-three/fiber";
import { PerspectiveCamera } from "@react-three/drei";
import { OrbitControls } from "@react-three/drei";
import { angleToRadians } from "./utils/angle";

import Pothos from "./Pothos";
import ContactForm from "./ContactForm";

const PothosObj = ({ position, scale, color }) => {
  const ref = useRef();

  useFrame((state, localDelta) => {
    ref.current.rotation.y += localDelta / 50;
  });

  return (
    <mesh
      position={position}
      ref={ref}
      rotation={[angleToRadians(0), angleToRadians(0), angleToRadians(0)]}
    >
      <Pothos scale={scale} args={[0.5, 32, 32]} />
    </mesh>
  );
};

function Contact(props, contactRef) {
  return (
    <>
      <div className="pages" ref={contactRef} id="contactPage">
        <div className="contactPageR3F">
          <div id="contactPageCanvas">
            <Canvas shadows>
              <PerspectiveCamera makeDefault position={[100, 0, 100]} />
              <OrbitControls target={[0, 0, 0]} />

              <PothosObj scale={65} position={[20, -50, 0]} color={"#1ef3d8"} />

              <ambientLight args={["#ffffff", 0.5]} />
              <directionalLight
                args={["#fffefc", 1.5]}
                position={[100, 100, 100]}
                castShadow
              />
            </Canvas>
          </div>
        </div>
        <div className="contactPageContent">
          <div className="formSide">
            <div className="formHeader">
              <p>Contact</p>
            </div>
            <ContactForm />
          </div>
          <div className="formSupportSide">
            <div className="formSupportSideContent">
              <div className="formSupportSideWelcome">
                <p className="welcomeMessage">
                  Want to work together? Have ideas for a project?
                </p>
                <p className="welcomeMessage">
                  Want to send me a photo of your pet?
                </p>
                <p className="welcomeMessage">Feel free to get in touch!</p>
              </div>

              <div className="contactInfoPaddyWagon">
                <div className="generalContactInfo">
                  <div className="contactInfoItem">
                    <div className="badge">
                      <FaPhone />
                    </div>
                    <p className="contactInfoItemInfo">
                      1 (630) 212-0327
                      <span className="toolTipText">click to copy</span>
                    </p>
                  </div>
                  <div className="contactInfoItem">
                    <div className="badge">
                      <FaEnvelope className="badgeIcon" />
                    </div>
                    <p
                      className="contactInfoItemInfo"
                      id="emailCopy"
                      onClick={() => {
                        navigator.clipboard.writeText("bjamescode@gmail.com");
                        console.log("copied");
                      }}
                    >
                      bjamescode@gmail.com
                      <span className="toolTipText">click to copy</span>
                    </p>
                  </div>
                  <div className="contactInfoItem">
                    <div className="badge">
                      <FaLocationDot />
                    </div>
                    <p className="contactInfoItemInfo">
                      Coolsville, USA
                      <span className="toolTipText">
                        you actually can't copy this one, this is nonsense
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="socialLinks">
          <div className="badgeLink">
            <a
              className="badgeLinkWrapper"
              href="https://github.com/NebjaminCode"
            >
              <DiGithubBadge size="50" />
            </a>
          </div>
          <div className="badgeLink">
            <a
              className="badgeLinkWrapper"
              href="https://www.linkedin.com/in/benjamincode/"
            >
              <FaLinkedin size="50" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default forwardRef(Contact);
