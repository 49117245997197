import React from "react";

function HomeMobile() {
  return (
    <div id="mobileOops">
      {/* <div className="mobileMessage">responsive design go <i>brrrr</i></div>
      <br></br> */}
      <div className="mobileMessage" id="mobileMessageSubHeader">Format optimization in progress. Please resize your window</div>
    </div >
  ) 
}

export default HomeMobile;
